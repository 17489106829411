<template>
    <div class="grid">
        <h1>Withdrawal</h1>
        <Toast/>
        <!-- <div class="col-12 md:col-4">
            <div class="card mb-0"  v-if="balanceLoading">
                <Skeleton width="100%" height="5rem"></Skeleton>
            </div>
            <div class="card mb-0" v-else>
                <div class="flex justify-content-between mb-3">
                    <div class="py-1">
                        <span class="block text-500 font-medium text-xl mb-3">Total Balance</span>
                        <div class="text-900 font-semibold text-4xl">&#x09F3; {{balance.balance}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-wallet text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card mb-0"  v-if="balanceLoading">
                <Skeleton width="100%" height="5rem"></Skeleton>
            </div>
            <div v-else class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium">Deposites</span>
                        <div class="text-900 font-medium text-xl mb-1">{{balance.deposites_count}}</div>
                        <span class="block text-500 font-medium">Amount</span>
                        <div class="text-900 font-medium text-xl">&#x09F3; {{balance.deposites_amount}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-credit-card text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card mb-0"  v-if="balanceLoading">
                <Skeleton width="100%" height="5rem"></Skeleton>
            </div>
            <div v-else class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium">Withdrawals</span>
                        <div class="text-900 font-medium text-xl mb-1">{{balance.withdrawal_count}}</div>
                        <span class="block text-500 font-medium">Amount</span>
                        <div class="text-900 font-medium text-xl">&#x09F3; {{balance.withdrawal_amount}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-money-bill text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-12">
            <div class="p-card p-2">
                <div class="surface-ground border-1 surface-border p-2">
                    <div class="grid md:mt-2">
                        <div class="col-12 lg:col-3 md:col-3">
                            <div class="text-xl font-semibold py-2">Request List</div>
                        </div>
                        <div class="col-12 lg:col-3 md:col-3">
                            <div class="field" >
                                <span class="p-float-label">
                                    <Dropdown
                                        class="w-full"
                                        id="status"
                                        v-model="status"
                                        :options="statusOpt"
                                        optionValue="value"
                                        optionLabel="name"
                                    />
                                    <label for="status">Status</label>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3 md:col-3">
                            <div class="flex flex-wrap ">
                                <div class="flex-1 flex align-items-center justify-content-center mx-2">
                                    <Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
                                </div>
                                <div class="flex-1 flex align-items-center justify-content-center mx-2">
                                    <Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3 md:col-3">
                            <Button label="Withdrawal Request" class="p-button-raised p-button-info w-full" @click="openRequestDialog"></Button>
                        </div>
                    </div>
                </div>
                <div class="custom-skeleton" v-if="withdrawalReqLoading">
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
                    <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
                </div>
                <div v-else>
                    <DataTable v-if="requestList.data != ''" ref="dt" :value="requestList.data" dataKey="id"  responsiveLayout="scroll">
                        <Column header="ID">
                            <template #body="slotProps">
                                #00{{slotProps.data.id}}
                            </template>
                        </Column>
                        <Column field="date" header="Request Date" headerStyle="min-width:8rem;">
                            <template #body="slotProps">
                                {{formatDate(slotProps.data.created_at)}}
                            </template>
                        </Column>
                        <Column field="status" header="Status">
                        </Column>
                        <Column field="amount" header="Amount"></Column>
                        <template #footer>
                            <Paginator v-model:first="requestList.from" :rows="paginationValue" :totalRecords="requestList.total" :rowsPerPageOptions="[2,10,20,50]" @page="onPage" >
                            </Paginator>
                        </template>
                    </DataTable>
                    <template v-else>
                        <div class="col">
                            <div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
                                <div class="flex align-items-center justify-content-center">
                                    <Message severity="info" :closable="false">No withdrawal request found!</Message>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <Dialog v-model:visible="requestDialog" :style="{width: '450px'}" header="Make Withdrawal Request" :modal="true" class="p-fluid" :closable="false">
                    <div>
                        <div class="p-1 text-color-secondary">
                            &bull; Your Current Balance: &#x09F3; {{balance.balance}}
                        </div>
                        <div class="p-1 text-color-secondary">
                            &bull; Withdrawable Amount: &#x09F3; <span v-if="balance.balance > 1000">{{balance.balance - 1000}}</span><span v-else>0</span>
                        </div>
                        <div class="p-1 text-color-secondary">
                            &bull; The amoun should be a divident of 500.
                        </div>
                    </div>
                    <div class="field mt-4">
                        <span class="p-float-label">
                            <InputNumber
                                :class="{'p-invalid': valid$.amount.$invalid && submitted}"
                                id="amount"
                                v-model="valid$.amount.$model"
                                showButtons
                                placeholder="Enter Amount"
                            />
                            <label for="amount">Amount</label>
                        </span>
                        <span v-if="valid$.amount.$error && submitted">
                            <span
                              id="amount-error"
                              v-for="(error, index) of valid$.amount.$errors"
                              :key="index"
                            >
                              <small class="p-error">{{error.$message.replace("Value", "Amount")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.amount.$invalid && submitted) || valid$.amount.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.amount.required.$message.replace("Value", "Amount") }}
                        </small>
                    </div>
                    <div >
                        <InlineMessage severity="error" v-if="balance.balance <= 1000">You do not have sufficient balance to sent a request</InlineMessage>
                    </div>
                    <template #footer>
                        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideRequestDialog"/>
                        <Button v-if="balance.balance <= 1000" label="Send Request" disabled="disabled" icon="pi pi-check" class="p-button p-button-raised" />
                        <Button label="Send Request" icon="pi pi-check" class="p-button p-button-raised" @click="withdrawalRequest(!valid$.$invalid)" :loading="loading"/>
                    </template>
                </Dialog>
            </div>
        </div> -->
    </div>
</template>

<script>
// import { ref } from 'vue'
// import { useRoute, useRouter } from 'vue-router';
// import { useToast } from 'primevue/usetoast';
// import Axios from 'axios';
// import moment from 'moment';
// import useVuelidate from '@vuelidate/core';
// import { required, numeric, minValue } from '@vuelidate/validators';

// export default {
//     setup () {
//         const router = useRouter();
//         const route = useRoute();
//         const toast = useToast();

//         const balance = ref();
//         const balanceLoading = ref(true);
//         const requestList = ref();
//         const withdrawalReqLoading = ref(true);
//         const loading = ref(false);
//         const status = ref();

//         const paginationValue = ref(10);
//         const pageValue = ref();

//         const requestDialog = ref(false);
//         const submitted = ref(false);

        
        

//         const statusOpt = ref([
//             {value: 'Pending', name: 'Pending'},
//             {value: 'Approved', name: 'Approved'},
//             {value: 'Declined', name: 'Declined'},
//         ]);

//         const getActiveQuerys = () => {
//             if(route.query.page != null){
//                 pageValue.value = route.query.page;
//             }
//             if(route.query.pagination != null){
//                 paginationValue.value = parseInt(route.query.pagination);
//             }
//             if(route.query.status != null){
//                 status.value = route.query.status;
//             }
//         };
//         getActiveQuerys();

//         const getBalance = () => {
//             Axios
//             .get("api/operator/wallet/balance")
//             .then((res) => {
//               if (res.data.response == "success") {
//                     balanceLoading.value = false;
//                     balance.value = res.data.data;
//                 }else{
//                     balanceLoading.value = true;
//                     console.log(res.data.message);
//                 }
//             })
//             .catch(err => {
//                 console.log(err);
//             })
//         }
//         getBalance();

//         const getRequestList = () => {
//             Axios
//             .get("api/operator/wallet/withdrawal-request-list",{
//                 params: {
//                     page: pageValue.value,
//                     pagination: paginationValue.value,
//                     status: status.value,
//                 }
//             })
//             .then(res => {
//                 if (res.data.response == "success") {
//                     withdrawalReqLoading.value = false;
//                     requestList.value = res.data.data;
//                     requestList.value.from = requestList.value.from-1;
//                     console.log(requestList.value);
//                 }else{
//                     console.log(res.data.message);
//                 }
//             })
//             .catch(err => {
//                 withdrawalReqLoading.value = true;
//                 console.log(err);
//             })
//         }
//         getRequestList();

//         const openRequestDialog = () => {
//             // submitted.value = false;
//             requestDialog.value = true;
//         };

//         const hideRequestDialog = () => {
//             // managerDialog.value = false;
//             requestDialog.value = false;
//             submitted.value = false;
//             // selectedItem.value = null;
//             // Header.value = 'Create Manager'

//             state.value.amount = '';
//             // state.email = '';
//             // state.mobile = '';
//             // state.password = '';
//             // stateInvite.email = '';
//         };

//         const state = ref({
//             amount : 0
//         });

//         const rules = {
//             amount : { required, numeric, minValue: minValue(1000) }
//         }

//         const valid$ = useVuelidate (rules, state);

//         const withdrawalRequest = (isFormValid) => {
//             submitted.value = true;

//             if (!isFormValid) {
//                 loading.value = false;
//                 return;
//             }
//             loading.value = true;
			
//             Axios
//             .post("api/operator/wallet/make-withdrawal-request", {
//                 amount: state.value.amount,
//             })
//             .then((res) => {
//                 loading.value = false;
//                 if(res.data.response=="success") {
//                     showToast("success", "Success", res.data.message);
//                     requestDialog.value = false;
//                     getRequestList();
//                     state.value.amount = '';
//                 }
//                 else{
//                     showToast("error", "Error", res.data.message);
//                     console.log(res.data.message);
//                     requestDialog.value = true;
//                 }
//             })
//             .catch((err) => {
//                 loading.value = false;
//                 showToast("error", "Error", "Something went wrong. Try again!");
//                 console.log(err);
//             });
//         };

//         const getFilteredList = () => {
//             withdrawalReqLoading.value = true;
//             // showFilterMenu.value = false;

//             var queryParams = {};
//             if(status.value != null){
//                 queryParams.status = status.value;
//             }

//             router.replace({ name: 'withdrawal-request', query: { ...queryParams } });

//             getRequestList();
//         };

//         const clearFilters = () => {
//             withdrawalReqLoading.value = true;
//             // showFilterMenu.value = false;

//             status.value = null;
//             router.replace();

//             getRequestList();
//         };

//         const onPage = (event) => {
//             pageValue.value = event.page + 1;
//             paginationValue.value = event.rows;
//             withdrawalReqLoading.value = true;
//             // showFilterMenu.value = false;

//             var queryParams = {};
//             if(pageValue.value != null){
//                 queryParams.page = pageValue.value;
//             }
//             if(paginationValue.value != null){
//                 queryParams.pagination = paginationValue.value;
//             }
//             router.replace({ name: 'withdrawal-request', query: { ...queryParams } });

//             getRequestList();
//         }

//         const formatDate = (rawDate) => {
//             return moment(rawDate).format("DD-MM-YYYY hh:mm:ss A");
//         }

//         const showToast = (severity, summary, detail) => {
//             toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
//         };
        
//         return {
//             showToast,
//             balance,
//             balanceLoading,
//             requestList,
//             withdrawalReqLoading,
//             formatDate,
//             paginationValue,
//             pageValue,
//             onPage,
//             statusOpt,
//             status,
//             getFilteredList,
//             clearFilters,
//             requestDialog,
//             openRequestDialog,
//             hideRequestDialog,
//             loading,
//             valid$,
//             submitted,
//             withdrawalRequest,
//         }
//     }
// }
</script>

<style>

</style>